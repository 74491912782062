import { readable } from "svelte/store";

export const currentPath = readable(
  typeof window !== "undefined" ? window.location.pathname : "/",
  (set) => {
    if (typeof window !== "undefined") {
      const update = () => set(window.location.pathname);

      window.addEventListener("popstate", update);

      const intervalId = setInterval(update, 100);

      return () => {
        // eslint-disable-next-line no-restricted-globals
        removeEventListener("popstate", update);
        clearInterval(intervalId);
      };
    }
    return false;
  },
);
