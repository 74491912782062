<style lang="scss">
  #bottomNav {
    align-items: center;
    border-top: 1px solid rgba(white, 0.3);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
    justify-content: center;
  }
</style>

<script>
  import TrainingHubSidebar from "../../../pages/TrainingHub/Sidebar/Sidebar.svelte";
  import ActionItems from "./ActionItems.svelte";
  import ProfileDropdown from "./ProfileDropdown.svelte";
</script>

<div id="bottomNav">
  <TrainingHubSidebar />
  <ActionItems />
  <ProfileDropdown />
</div>
