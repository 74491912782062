import { swRequest } from "./sw-request.js";

export const getTasks = () =>
  swRequest({
    path: "/api/training_hub/tasks",
  });

export const updateTask = (body) =>
  swRequest({
    path: "/api/training_hub/tasks",
    method: "POST",
    body,
  });
