import { derived } from "svelte/store";
import { getOrganization } from "../api/organizations";
import { userStore } from "./user-store";

export const organizationsStore = derived(
  userStore,
  ($userStore, set) => {
    if (!$userStore?.organizationId) {
      set(null);
      return;
    }

    getOrganization($userStore?.organizationId).then((data) => {
      set(data.organization);
    });
  },
  null,
);
