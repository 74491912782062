import { derived } from "svelte/store";
import {
  cookieValue,
  yesNoConfirm,
} from "../../../../../assets/js/src/general/utils";
import { organizationsStore } from "../../../../api-stores/organizations-store";
import { userStore } from "../../../../api-stores/user-store";
import { signOut } from "../../../../api/signout";

const baseMenuItems = [
  { icon: "fa-regular fa-user fa-fw", name: "Profile", link: "/profile" },
  {
    icon: "fab fa-chrome fa-fw",
    name: "Extension",
    link: "https://chrome.google.com/webstore/detail/sourcewhale/hfnnombjdnokggcepagcndckmngedifk",
    target: "_blank",
  },
  {
    icon: "fa-regular fa-award fa-fw",
    name: "Training",
    link: "/training",
  },
  {
    icon: "fa-regular fa-ban fa-fw",
    name: "Unsubscribes",
    link: "/unsubscribes",
  },
  {
    icon: "fa-regular fa-seal-question fa-fw",
    name: "Product Ideas",
    link: "https://sourcewhale.canny.io/feature-requests",
    target: "_blank",
  },
  { type: "divider" },
  {
    icon: "fa-regular fa-plug fa-fw",
    name: "Integrations",
    link: "/integrations",
  },
  {
    icon: "fa-regular fa-tools fa-fw",
    name: "Admin",
    link: "/admin",
  },
];

const getDemoMenuItems = (demoProviders) => {
  if (!demoProviders?.length) return [];

  return [
    {
      icon: "fa-regular fa-presentation-screen",
      name: "Demo",
      menu: demoProviders.map((provider) => ({
        name: provider.title,
        link: `/internal-api/demo/impersonate?provider=${provider.provider}`,
        imgSrc: `/assets/img/crm/${provider.provider}.png`,
      })),
    },
  ];
};

const getStopDemoMenuItem = (isDemoing) => {
  if (!isDemoing) return [];

  return [
    {
      icon: "fa-regular fa-presentation-screen",
      name: "Stop Demo",
      link: "/internal-api/demo/depersonate",
    },
  ];
};

const getInternalMenuItem = (isInternal) => {
  if (!isInternal) return [];

  return [
    {
      icon: "fa-regular fa-ticket fa-fw",
      name: "Create Ticket",
      link: "/internal/requests",
      target: "_blank",
    },
  ];
};

const getTeamMenuItems = (user, teams) => {
  if (!user?.isAdmin || !user.organizationId || user.isTest || !teams?.length) {
    return [];
  }

  const teamsList = teams
    .sort((a, b) => a.teamName.localeCompare(b.teamName))
    .filter((team) => team.teamId !== user.teamId)
    .map((team) => ({
      name: `View ${team.teamName}`,
      link: `/api/admin/impersonate?teamId=${team.teamId}&path=${window.location.pathname}`,
    }));

  if (!teamsList.length) return [];

  return [
    {
      icon: "fa-regular fa-users fa-fw",
      name: "View Teams",
      menu: teamsList,
    },
  ];
};

const getAdditionalItems = (user, teams, isDemoing) => {
  const items = [
    ...getDemoMenuItems(user?.demoProviders),
    ...getStopDemoMenuItem(isDemoing),
    ...getTeamMenuItems(user, teams),
    ...getInternalMenuItem(user?.isInternal),
  ];

  if (!items.length) return [];

  return [{ type: "divider" }, ...items, { type: "divider" }];
};

export const profileMenuItemsStore = derived(
  [userStore, organizationsStore],
  ([$userStore, $organizationsStore]) => {
    const user = $userStore;
    const isDemoing = cookieValue("demoing") === "true";

    const filteredMenu = baseMenuItems.filter(
      (item) => !(item.name === "Admin" && !user.isAdmin),
    );

    const menuItems = filteredMenu.map((item) => {
      if (item.link === "/training") {
        return {
          ...item,
          name: user.trainingHubStatus ? "Training Hub" : "Training",
        };
      }
      return item;
    });

    return [
      ...menuItems,
      ...getAdditionalItems(user, $organizationsStore?.teams, isDemoing),
      {
        icon: "fa-regular fa-sign-out-alt fa-fw",
        name: "Sign Out",
        onClick: () =>
          yesNoConfirm("Are you sure you would like to sign out?", () =>
            signOut(),
          ),
      },
    ];
  },
);
