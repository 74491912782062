import { derived } from "svelte/store";
import { userHasDialler } from "../../../../../assets/js/src/general/utils";
import { todosCountStore } from "./todos-count-store";

const baseNavItems = [
  {
    id: "dashboard",
    name: "Dashboard",
    icon: "fa-regular fa-grid-2",
    link: "/dashboard",
    display: true,
  },
  {
    id: "campaigns",
    name: "Campaigns",
    icon: "fa-regular fa-megaphone",
    link: "/campaigns",
    display: true,
  },
  {
    id: "todos",
    name: "To-Dos",
    icon: "fa-regular fa-check-square",
    link: "/todo",
    display: true,
  },
  {
    id: "calls",
    name: "Calls",
    icon: "fa-regular fa-phone",
    link: "/calls",
    display: true,
  },
  {
    id: "outbox",
    name: "Outbox",
    icon: "fa-regular fa-box-archive",
    link: "/outbox",
    display: true,
  },
  {
    id: "people",
    name: "People",
    icon: "fa-regular fa-user-group",
    menu: [
      {
        name: "Contacts",
        icon: "fa-regular fa-address-book fa-fw",
        link: "/contacts",
      },
      {
        name: "Projects",
        icon: "fa-regular fa-folder-user fa-fw",
        link: "/projects",
      },
      {
        name: "Upload",
        icon: "fa-regular fa-file-arrow-up fa-fw",
        link: "/upload",
      },
      {
        name: "Search",
        icon: "fa-regular fa-magnifying-glass fa-fw",
        link: "/search-builder",
      },
    ],
    display: true,
  },
  {
    id: "reports",
    name: "Reports",
    icon: "fa-regular fa-chart-pie",
    link: "/reports",
    display: true,
  },
];

export const navItemsStore = derived([todosCountStore], ([$todosCountStore]) =>
  baseNavItems
    .map((item) => {
      if (item.id === "calls") {
        return {
          ...item,
          badgeValue: userHasDialler() ? null : "New",
          badgeClass: "new",
        };
      }
      if (item.id === "todos" && $todosCountStore && $todosCountStore !== "0") {
        return { ...item, badgeValue: $todosCountStore };
      }
      return item;
    })
    .filter((item) => item.display),
);
