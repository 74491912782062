import { derived } from "svelte/store";
import { cookieValue } from "../../../../../assets/js/src/general/utils";
import { userStore } from "../../../../api-stores/user-store";

const defaultState = {
  showInternalAdmin: false,
  showDepersonate: false,
  internalAdmin: null,
  depersonate: null,
};

export const internalAdminStore = derived(
  userStore,
  ($userStore, set) => {
    const user = $userStore;
    const isImpersonating = cookieValue("impersonating") === "true";

    if (!user) {
      set(defaultState);
      return;
    }

    set({
      showInternalAdmin: Boolean(user.isInternalAdmin) && !isImpersonating,
      showDepersonate: isImpersonating,
      internalAdmin: {
        icon: "fa-regular fa-screwdriver-wrench fa-fw",
        name: "Internal Admin",
        link: "/internal/admin?tab=users",
      },
      depersonate: {
        icon: "fa-regular fa-user-unlock fa-fw",
        name: "Return to My Account",
        link: "/internal-api/admin/depersonate",
      },
    });
  },
  defaultState,
);
