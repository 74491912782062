// This file was generated automatically as a part of the SourceWhale ES6 refactor 🐳.
import { initializeSidebar } from "../../../svelte/src/bindings/sidebar.js";
import { bindTestPanel } from "../../../svelte/src/bindings/test-panel-prod.js";
import { initializeConfig } from "../src/general/config.js";
import { initializeTheme } from "../src/general/theme.js";
import { initializeTracking } from "../src/general/tracking.js";
import { initializeGeneralUtils } from "../src/general/utils.js";
import { initializeVisibilities } from "../src/general/visibilities.js";

bindTestPanel();
initializeTheme();
initializeConfig();
initializeGeneralUtils();
initializeVisibilities();
initializeSidebar();
initializeTracking();
