<style lang="scss">
  @use "../../../../../assets/css/design-tokens/_colors";

  .menuItem {
    width: 100%;
    align-items: center;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    padding: 0.75rem;
    position: relative;

    &:hover {
      background-color: rgba(white, 0.1);
    }

    &.muted {
      border-bottom: 1px solid colors.$INTERFACE_SW_Grey10;
      border-radius: 0;

      &:hover {
        background-color: rgba(83, 83, 83, 0.1);
      }
    }
  }
</style>

<script>
  import AlertMenuItemContent from "./AlertMenuItemContent.svelte";
  import DropdownMenu from "./DropdownMenu.svelte";
  import StandardMenuItemContent from "./StandardMenuItemContent.svelte";

  export let item;
  export let type;
  export let offsetElement;

  let hideTimer;
  let displaySubMenu = false;
  let targetElement;

  const handleClick = () => {
    if (item.onClick) {
      item.onClick();
    }
  };

  const showSubMenu = () => {
    clearTimeout(hideTimer);
    displaySubMenu = true;
  };

  const hideSubMenu = () => {
    hideTimer = setTimeout(() => {
      displaySubMenu = false;
    }, 150);
  };
</script>

<a
  href="{item.link || '#'}"
  target="{item.target}"
  bind:this="{targetElement}"
  class="menuItem {type}"
  data-testId="menuItem_{item.name.replace(' ', '')}"
  role="menuitem"
  tabindex="0"
  on:mouseenter="{showSubMenu}"
  on:mouseleave="{hideSubMenu}"
  on:click="{handleClick}"
  on:keydown="{handleClick}"
>
  {#if item.menu}
    <StandardMenuItemContent {item} {type} />
  {:else if item.type === "alert"}
    <AlertMenuItemContent {item} {type} />
  {:else}
    <StandardMenuItemContent {item} {type} />
  {/if}

  {#if item.menu && displaySubMenu}
    <DropdownMenu
      items="{item.menu}"
      height="20rem"
      {type}
      {targetElement}
      {offsetElement}
    />
  {/if}
</a>
