<style lang="scss">
  #navMenu {
    flex-direction: column;
    display: flex;
    gap: 1px;
    justify-content: center;
  }
</style>

<script>
  import { userTodosStore } from "../../../../../api-stores/user-store";
  import { navItemsStore } from "../../stores/nav-items-store";
  import NavItem from "./NavItem.svelte";

  userTodosStore.once();
</script>

<div id="navMenu" data-testid="navMenu">
  {#each $navItemsStore as navItem}
    <NavItem {navItem} />
  {/each}
</div>
