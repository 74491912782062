<style lang="scss">
  @import "../../../../assets/css/global.scss";

  .avatar {
    align-items: center;
    background-position: center;
    background-size: cover;
    border: 2px solid $light-gray;
    border-radius: 50%;
    display: flex;
    justify-content: center;

    // Sizes
    &-sm {
      @extend .text-body-3-bold;
      @include size(1.4rem);
    }

    &-md {
      @extend .text-body-2-bold;
      @include size(2rem);
    }

    &-lg {
      padding: 1rem;
      @extend .text-body-1-bold;
      @include size(2.5rem);
    }

    &-xl {
      @extend .text-body-1-bold;
      @include size(2.75rem);
    }

    // Font color
    &.text-white {
      color: white;
    }
  }
</style>

<script>
  export let size = "md";
  export let cls = "";
  export let image = "";
  export let name = "";
  export let displayInitials = false;

  const getInitials = () => {
    if (!displayInitials) return "";

    const nameParts = name.split(" ");

    const firstInitial = nameParts[0]?.[0] || "";

    const secondInitial = (size !== "sm" && nameParts[1]?.[0]) || "";

    return (firstInitial + secondInitial).toUpperCase();
  };

  const initials = getInitials();
</script>

<div
  class="avatar avatar-{size} {cls}"
  style="background-image: url({image})"
  alt="{name}"
>
  {#if !image && displayInitials}
    {initials}
  {/if}
</div>
