<style lang="scss">
  @keyframes blink {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
      opacity: 1;
    }
    100% {
      transform: scale(1.6);
      opacity: 0;
    }
  }

  svg {
    position: absolute;
    transform-origin: center;
    left: calc(var(--left) - 8px);
    top: calc(var(--top) - 8px);
    transition: left 0.5s;
  }

  svg g circle {
    transform-origin: center;
    animation: blink 2s infinite;
  }
</style>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <g>
    <circle cx="12" cy="12" r="6.85714" fill="#1EBFB1"></circle>
  </g>
  <circle
    cx="12"
    cy="12"
    r="6.85714"
    fill="#E8F7F5"
    stroke="#1EBFB1"
    stroke-width="2"
  ></circle>
</svg>
