<style lang="scss">
  @use "../../../../../assets/css/design-tokens/_colors";
  @use "../../../../../assets/css/mixins";
  @import "../../../../../assets/css/_colors";

  .menuItem {
    width: 100%;
    align-items: flex-start;
    color: white;
    display: flex;
    font-size: 0.875rem;
    gap: 0.75rem;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    &.muted {
      color: $text-black;
    }
  }

  .iconContainer {
    align-items: center;
    border-radius: 5px;
    display: flex;
    font-size: 13px;
    flex-shrink: 0;
    justify-content: center;
    @include mixins.size(1.6875rem);

    &.notification {
      background-color: colors.$SYSTEM_Information10;
      color: colors.$SYSTEM_Information100;
    }

    &.integration {
      background-color: colors.$SYSTEM_Error10;
      color: colors.$SYSTEM_Error100;
    }
  }

  .name {
    display: flex;
    flex-direction: column;
    gap: 2px;
    line-height: 1.2;

    .timestamp {
      color: colors.$gray;
      font-size: 0.75rem;
      line-height: 1.4;
    }
  }
</style>

<script>
  export let item;
  export let type;

  $: iconContainerClass =
    item.kind === "integrationDisconnected" ? "integration" : "notification";
</script>

<div
  class="menuItem {type}"
  data-testId="menuItem_{item.name.replace(' ', '')}"
>
  <div class="iconContainer {iconContainerClass}">
    <i class="icon {item.icon}"></i>
  </div>
  <span class="name">
    <!--eslint-disable-next-line svelte/no-at-html-tags -->
    <span class="name">{@html item.name}</span>
    {#if item.timestamp}
      <span class="timestamp">{item.timestamp}</span>
    {/if}
  </span>
</div>
