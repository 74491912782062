<style lang="scss">
  @use "../../../../assets/css/design-tokens/_colors";
  @import "../../../../assets/css/_colors";
  @import "../../../../assets/css/_variables";

  nav#sidebar {
    width: 100%;
    height: 100%;
    align-items: center;
    background-color: colors.$sw-blue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.isImpersonating {
      background-color: $red;
    }
  }
</style>

<script>
  import { cookieValue } from "../../../../assets/js/src/general/utils";
  import BottomNav from "./BottomNav/BottomNav.svelte";
  import TopNav from "./TopNav/TopNav.svelte";

  $: isImpersonating = cookieValue("impersonating") === "true";
</script>

<nav id="sidebar" data-testid="sidebar" class:isImpersonating>
  <TopNav />
  <BottomNav />
</nav>
