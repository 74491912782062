import { derived } from "svelte/store";
import { userTodosStore } from "../../../../api-stores/user-store";

export const todosCountStore = derived(
  [userTodosStore],
  ([$userTodosStore]) => {
    const { toDos = 0 } = $userTodosStore || {}; // empty dict to prevent error
    return toDos > 99 ? "99+" : `${toDos}`;
  },
);
