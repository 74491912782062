<style lang="scss">
  @import "../../../../../assets/css/_colors";

  .menuItem {
    width: 100%;
    align-items: center;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 0.75rem;
    gap: 0.5rem;
    padding: 0;
    text-decoration: none;

    &.muted {
      color: $text-black;
    }
  }

  .icon {
    font-size: 1rem;

    &-admin {
      color: $red;
    }
  }

  .subMenuIndicator {
    font-size: 60%;
    margin-left: auto;
  }
</style>

<script>
  export let item;
  export let type;
</script>

<div class="menuItem {type}">
  <i class="icon {item.icon}"></i>
  <span class="name">{item.name}</span>
  {#if item.menu}
    <i class="fa-solid fa-angles-right subMenuIndicator"></i>
  {/if}
</div>
