<style lang="scss">
  @use "../../../../../assets/css/mixins";
  @use "../../../../../assets/css/design-tokens/colors";

  .alertIndicator {
    position: absolute;
    background-color: colors.$pink;
    border-radius: 50%;
    right: 0.35rem;
    top: 0.35rem;
    @include mixins.size(0.5rem);

    &.isImpersonating {
      background-color: colors.$warning;
    }
  }
</style>

<script>
  import { onMount } from "@lifecycle";
  import { cookieValue } from "../../../../../assets/js/src/general/utils";
  import { getAlerts } from "../../../../api/alerts";
  import DropdownMenu from "../DropdownMenu/DropdownMenu.svelte";
  import { activeDropdown } from "../stores/dropdown-store";
  import NavItem from "./NavItem.svelte";

  let alerts = [];
  let targetElement;
  let hasAlerts = false;

  $: isOpen = $activeDropdown === "notifications";
  $: isImpersonating = cookieValue("impersonating") === "true";

  const formatTimeAgo = (timestamp) => {
    const now = Date.now() / 1000;
    const diff = now - timestamp;
    const days = Math.floor(diff / (60 * 60 * 24));

    switch (true) {
      case days === 0:
        return "Today";
      case days === 1:
        return "Yesterday";
      case days < 7:
        return `${days} days ago`;
      case days < 30:
        return `${Math.floor(days / 7)} weeks ago`;
      default:
        return `${Math.floor(days / 30)} months ago`;
    }
  };

  onMount(() => {
    getAlerts().then((apiAlerts) => {
      // Public holiday alert only appears as a toast, remove it from the dropdown
      const filteredAlerts = apiAlerts.filter(
        (alert) => alert.kind !== "publicHoliday",
      );
      if (!filteredAlerts.length) return;

      hasAlerts = true;
      alerts = filteredAlerts
        .map((alert) => ({
          name: alert.text,
          icon: alert.icon,
          link: alert.link || "#",
          type: "alert",
          kind: alert.sw__modifier ? "notification" : "integrationDisconnected",
          timestamp: alert.sw__lastModified
            ? formatTimeAgo(alert.sw__lastModified)
            : null,
          rawTimestamp: alert.sw__lastModified || 0,
        }))
        .sort((a, b) => b.rawTimestamp - a.rawTimestamp);
    });
  });

  const handleClick = (event) => {
    if (targetElement && targetElement.contains(event.target)) return;
    if (isOpen) activeDropdown.set(null);
  };

  const handleNavClick = () => {
    activeDropdown.set(isOpen ? null : "notifications");
  };
</script>

<svelte:window on:click="{handleClick}" />

<div bind:this="{targetElement}" class="alertsContainer">
  <NavItem
    onClick="{handleNavClick}"
    title="Notifications"
    classList="{isOpen ? 'isOpen' : ''}"
  >
    <i class="fa-regular fa-bell"></i>
    {#if hasAlerts}
      <span class="alertIndicator" class:isImpersonating></span>
    {/if}
  </NavItem>

  {#if isOpen}
    <DropdownMenu
      items="{alerts}"
      width="16.25rem"
      height="20rem"
      type="muted"
      offsetElementId="sidebar"
      {targetElement}
    />
  {/if}
</div>
