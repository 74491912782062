<style lang="scss">
  #topNav {
    width: 100%;
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1.5rem;
    overflow-x: visible;
    overflow-y: auto;
    padding: 1rem 0;
    padding-bottom: 0;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(black, 0.35);
      border-radius: 16px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
</style>

<script>
  import Logo from "./Logo.svelte";
  import NavMenu from "./NavMenu/NavMenu.svelte";
</script>

<div id="topNav" data-testid="topNav">
  <Logo />
  <NavMenu />
</div>
