<style lang="scss">
  @import "../../../../../assets/css/_mixins";

  .logo {
    display: inline-flex;
    @include size(3rem);

    img {
      width: 100%;
    }
  }
</style>

<a class="logo" href="/dashboard">
  <img src="../../../../assets/img/logo/svg/icon-white.svg" alt="Logo" />
</a>
