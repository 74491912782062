<style lang="scss">
  .skeleton {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite linear;
    border-radius: 0.5rem;
  }

  @keyframes shimmer {
    0% {
      background-position: -200% 0;
    }
    100% {
      background-position: 200% 0;
    }
  }

  .skeleton-task-group {
    height: 500px;
    width: 420px;
  }

  .skeleton-task {
    height: 120px;
  }

  .skeleton-header {
    position: relative;
    top: 10px;
    display: grid;
    width: 100%;
    grid-template-columns: auto 1fr auto;
    gap: 1rem;
    align-items: center;

    &.padding-margin {
      padding: 1.5rem;
    }

    &.main-header {
      .left-content {
        margin-left: 0.5rem;
        .title {
          width: 700px;
          height: 42px;
          margin-bottom: 24px;
        }
        .subtitle {
          width: 600px;
          height: 20px;
        }
      }
      .right-content {
        width: 380px;
        height: 140px;
        border-radius: 8px;
        justify-self: end;
      }
    }

    &.header {
      .icon {
        width: 50px;
        height: 50px;
      }
      .content {
        .title {
          width: 500px;
          height: 24px;
          margin-bottom: 8px;
        }
        .subtitle {
          width: 400px;
          height: 16px;
        }
      }
      .right {
        margin-right: 1rem;
        width: 100px;
        height: 16px;
      }
    }
  }
</style>

<script>
  export let type;
  export let count;
</script>

{#if type === "main-header"}
  <div class="skeleton-header main-header">
    <div class="left-content">
      <div class="skeleton title"></div>
      <div class="skeleton subtitle"></div>
    </div>
    <div class="skeleton right-content"></div>
  </div>
{:else if type === "task-group"}
  <div class="skeleton skeleton-task-group"></div>
{:else if type === "header"}
  <div class="skeleton-header padding-margin header">
    <div class="skeleton icon"></div>
    <div class="content">
      <div class="skeleton title"></div>
      <div class="skeleton subtitle"></div>
    </div>
    <div class="skeleton right"></div>
  </div>
{:else if type === "task"}
  {#each Array(count) as _}
    <div class="skeleton skeleton-task"></div>
  {/each}
{/if}
