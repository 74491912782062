<style lang="scss">
  @import "../../../../../assets/css/global.scss";
  @import "./_TrainingHubMixins.scss";

  .icon-wrapper {
    align-items: center;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;

    &.large {
      padding: 0.8rem;
      align-self: flex-start;
      i {
        font-size: 1.2rem;
      }
    }

    &.medium {
      padding: 0.5rem;

      width: 2.5rem;
      height: 2.5rem;
      i {
        font-size: 1.1rem;
      }
    }

    &:has(.fa-user) i {
      padding: 0px 3.5px;
    }

    @mixin blue {
      background-color: $light-blue;

      i {
        color: $blue;
      }
    }

    @mixin green {
      background-color: $light-green;

      i {
        color: $green;
      }
    }

    @mixin orange {
      background-color: $light-orange;

      i {
        color: $orange;
      }
    }

    &.blue {
      @include blue();
    }

    &.green {
      @include green();
    }

    &.orange {
      @include orange();
    }

    &.purple {
      background-color: $light-purple;

      i {
        color: $purple;
      }
    }

    &.pink {
      background-color: $light-pink;

      i {
        color: $pink;
      }
    }

    &:has(.outlook-logo) {
      .outlook-logo {
        width: 20px;
        height: 20px;
      }
    }

    &:has(.sourcewhale-logo) {
      background-color: $light-blue;
      .sourcewhale-logo {
        width: 20px;
        height: 20px;
      }
    }

    &:not(.blue, .green, .orange, .purple, .pink) {
      &:has(.fa-user-group) {
        @include green();
      }

      &:has(.fa-user) {
        @include blue();
      }

      &:has(.fa-screen-users) {
        @include orange();
      }
    }

    @include training-hub-locked();
  }
</style>

<script>
  export let icon;
  export let locked = false;
  export let size = "medium";
  export let colorIndex = -1;

  let [blue, green, orange, purple, pink] = [...Array(5).keys()].map(
    (i) => colorIndex > -1 && colorIndex % 5 === i,
  );

  const logos = {
    "outlook-logo": {
      src: "/assets/img/training-hub/outlook-logo.png",
      alt: "Outlook Logo",
    },
    "sourcewhale-logo": {
      src: "/assets/img/logo/png/icon300.png",
      alt: "Sourcewhale Logo",
    },
  };
</script>

<div
  class="icon-wrapper {size}"
  class:blue
  class:green
  class:orange
  class:purple
  class:pink
  class:locked
>
  {#if logos[icon]}
    <img class="{icon}" alt="{logos[icon].alt}" src="{logos[icon].src}" />
  {:else}
    <i class="{icon}"></i>
  {/if}
</div>
