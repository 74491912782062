<style lang="scss">
  #profileDropdown {
    display: inline-block;
    margin-top: auto;
    position: relative;

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
    }
  }
</style>

<script>
  import { userStore } from "../../../../api-stores/user-store";
  import Avatar from "../../../components/legacy/Avatar.svelte";
  import DropdownMenu from "../DropdownMenu/DropdownMenu.svelte";
  import { activeDropdown } from "../stores/dropdown-store";
  import { profileMenuItemsStore } from "../stores/profile-menu-store";

  let targetElement;
  $: isOpen = $activeDropdown === "profile";
  $: ({ name = "", picture: image = "" } = $userStore || {});

  const handleClick = (event) => {
    if (targetElement && targetElement.contains(event.target)) return;
    if (isOpen) activeDropdown.set(null);
  };

  const handleButtonClick = () => {
    activeDropdown.set(isOpen ? null : "profile");
  };
</script>

<svelte:window on:click="{handleClick}" />

<div id="profileDropdown" bind:this="{targetElement}">
  <button on:click|stopPropagation="{handleButtonClick}">
    <Avatar size="xl" cls="text-white" {name} {image} displayInitials />
  </button>

  {#if isOpen}
    <DropdownMenu
      items="{$profileMenuItemsStore}"
      type="primary"
      offsetElementId="sidebar"
      {targetElement}
    />
  {/if}
</div>
