import Sidebar from "../elements/Sidebar/Sidebar.svelte";
import { bindToShadowDOM } from "../utils/shadow-dom";

export const initializeSidebar = () =>
  bindToShadowDOM({
    elementId: "sidebar-wrapper",
    component: Sidebar,
    stylesheets: [
      "/assets/css/reset.css",
      "/assets/lib/fontawesome/css/all.min.css",
    ],
  });
