import { derived, writable } from "svelte/store";
import { trainingHubTaskStore } from "../../../../api-stores/training-hub-store.js";

export const currentTask = derived(
  trainingHubTaskStore,
  ($trainingHubTaskStore) =>
    $trainingHubTaskStore.tasks.find((task) => task.status === "current"),
);

export const currentTaskIndex = derived(
  [currentTask, trainingHubTaskStore],
  ([$currentTask, $trainingHubTaskStore]) => {
    if (!$currentTask) return -1;

    return $trainingHubTaskStore.tasks
      .filter(
        (task) =>
          !$trainingHubTaskStore.taskGroups ||
          task.taskGroupId === $currentTask.taskGroupId,
      )
      .findIndex((task) => task.taskId === $currentTask.taskId);
  },
);

export const showDialog = writable(false);
