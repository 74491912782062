import { getTasks } from "../api/training-hub.js";
import { SwApiStore } from "./sw-api-store.js";

export const trainingHubTaskStore = SwApiStore({
  get: () => getTasks(),
  init: () => ({ tasks: [], taskCards: [], totalProgress: {} }),
  onMessage: ({ message }) => {
    if (message.kind !== "training-hub-task") return;
    trainingHubTaskStore.get();
  },
});
