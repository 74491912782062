<style lang="scss">
  @import "../../../../../assets/css/global.scss";
  .action-button {
    font-size: 0.9rem;
    max-width: 230px;
    min-width: 180px;
    width: 100%;
    text-align: center;
    border: 0;
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    text-decoration: none;

    @mixin locked() {
      & {
        &.locked {
          background-color: #f5f5f5;
          color: #677185;
          cursor: not-allowed;
          opacity: 0.7;
          pointer-events: none;
          border-color: transparent;
          font-weight: bold;
        }
      }
    }

    &.primary {
      background-color: $primary-color;
      color: white;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $blue;
      }

      &:active {
        background-color: $dark-blue;
      }
      @include locked();
    }

    &.secondary {
      border: 1px solid $primary-color;
      transition: all 0.3s ease;

      &:hover {
        background-color: $light-gray;
        border: 1px solid transparent;
      }

      &:active {
        background-color: $light-blue;
        border: 1px solid $dark-blue;
        color: $dark-blue;
      }
      @include locked();
    }
  }
</style>

<script>
  import { sendToHeap } from "../../../../../assets/js/src/general/utils";

  let cls = "";
  export { cls as class };
  export let heapEventName = "";
  export let heapProperties = {};
  export let onclick = () => {};

  const wrappedClick = (event) => {
    onclick(event);

    if (heapEventName) {
      sendToHeap(heapEventName, { ...(heapProperties || {}) });
    }
  };

  $: if ($$restProps.disabled) cls += " locked";
</script>

{#if $$restProps.href}
  <a
    class="action-button {cls}"
    href="{$$restProps.href}"
    on:click="{wrappedClick}"
    {...$$restProps}
  >
    <slot />
  </a>
{:else}
  <button
    class="action-button {cls}"
    on:click="{wrappedClick}"
    {...$$restProps}
  >
    <slot />
  </button>
{/if}
