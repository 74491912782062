<style lang="scss">
  @import "../../../../../assets/css/global.scss";
  article {
    background-color: $dark-blue;
    background-image: url("/assets/img/common/background-lines.svg");
    border-radius: 0.5rem;
    height: 100%;
    width: 100%;
    min-height: 150px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h2 {
      font-weight: normal;
      font-size: 0.85rem;
      color: $primary-background-color;
      margin-bottom: 0;
    }
  }

  .widget-description {
    color: white;
    font-size: 1rem;
    font-weight: 700;
  }

  .task-progress-text {
    display: flex;
    font-size: 0.8rem;
    color: white;
    margin: 0;
    justify-content: flex-end;
    gap: 0.25rem;
  }

  .task-progress-bar {
    display: flex;
    position: relative;
    align-items: flex-start;
    width: 100%;
    height: 6px;
    border-radius: 0.5rem;
    background-color: $light-green;
    transition: width 0.5s;

    .completed {
      border-radius: 0.5rem;
      height: 6px;
      background-color: $turquoise;
      transition: width 0.5s;
    }
  }
</style>

<script>
  import IconCircle from "./IconCircle.svelte";
  import { firstNameStore } from "../stores";
  import { trainingHubTaskStore } from "../../../../api-stores/training-hub-store";

  $: completedTasks = $trainingHubTaskStore.totalProgress.completedTasks || 0;
  $: totalTasks = $trainingHubTaskStore.totalProgress.totalTasks || 0;
  $: completedPercent =
    totalTasks === 0 ? 0 : (completedTasks / totalTasks) * 100;

  const computeEncouragement = (n, m) => {
    if (n === 0) return "Get Started";
    if (n === m || n >= 5) return "Excellent!";
    if (n >= 3) return "Nice Work";
    return "Keep Going";
  };

  $: encouragement = computeEncouragement(completedTasks, totalTasks);
</script>

<article>
  <h2>Welcome {$firstNameStore}!</h2>
  <span class="widget-description"
    >Complete your onboarding tasks to learn more about SourceWhale</span
  >
  <div class="task-progress-text">
    <b>{completedTasks} / {totalTasks}</b>
    <b>Tasks Complete</b>
    <p>- {encouragement}</p>
  </div>
  <div class="task-progress-bar">
    <div class="completed" style="width: {completedPercent}%"></div>
    <IconCircle --top="-1px" --left="{completedPercent}%" />
  </div>
</article>
