<script>
  import { internalAdminStore } from "../stores/internal-admin-store";
  import NavItem from "./NavItem.svelte";
  import NotificationsDropdown from "./NotificationsDropdown.svelte";

  let adminActions = [];

  $: {
    const { depersonate, internalAdmin, showDepersonate, showInternalAdmin } =
      $internalAdminStore || {};

    adminActions = [
      showDepersonate && depersonate,
      showInternalAdmin && internalAdmin,
    ].filter(Boolean);
  }
</script>

<div class="actionItems">
  {#each adminActions as { link: href, name: title, icon }}
    <NavItem {href} {title}>
      <i class="{icon}"></i>
    </NavItem>
  {/each}
  <NotificationsDropdown />
</div>
